import { makeStyles } from '@material-ui/core/styles';
import LaunchIconImg from '../../assets/images/launch_icon.png'
import LauncherImg from '../../assets/images/launcher.png'
import MinimisedIconImg from '../../assets/images/minimised_icon.png'
import LauncherHoverImg from '../../assets/images/launcher_hover.png'
import LauncherOpenImg from '../../assets/images/launcher_open.png'
import LauncherOpenHoverImg from '../../assets/images/launcher_open_hover.png'

export default makeStyles(theme => ({
  launcherButton: {
    zIndex: 2000,
    margin: theme.spacing(1),
    position: 'fixed',
    bottom: 10,
    right: ({ WIDGET_LOCATION }) => WIDGET_LOCATION  !== 'left'? 10 : 'none',
    left: ({ WIDGET_LOCATION }) => WIDGET_LOCATION  === 'left'? 10 : 'none',
    background: ({USE_CUSTOM_LAUNCHER, USE_CUSTOM_LAUNCHER_ICON, GLOBAL_COLOR}) => USE_CUSTOM_LAUNCHER ? `url(${LauncherImg}) no-repeat center center`: USE_CUSTOM_LAUNCHER_ICON ? `url(${LaunchIconImg}) no-repeat center center, ${GLOBAL_COLOR}` : GLOBAL_COLOR,
    backgroundSize: ({USE_CUSTOM_LAUNCHER, USE_CUSTOM_LAUNCHER_ICON}) => USE_CUSTOM_LAUNCHER || USE_CUSTOM_LAUNCHER_ICON ? 'cover !important': 'auto',
    boxShadow: ({USE_CUSTOM_LAUNCHER}) => USE_CUSTOM_LAUNCHER? 'none': 'auto',
    height: ({USE_CUSTOM_LAUNCHER, CUSTOM_LAUNCHER_HEIGHT, launchButtonSize}) => USE_CUSTOM_LAUNCHER? CUSTOM_LAUNCHER_HEIGHT: launchButtonSize,
    width: ({USE_CUSTOM_LAUNCHER, CUSTOM_LAUNCHER_WIDTH, launchButtonSize, chatIsOpen}) => USE_CUSTOM_LAUNCHER? CUSTOM_LAUNCHER_WIDTH: launchButtonSize,
    borderBottomRightRadius: ({ WIDGET_LOCATION }) => WIDGET_LOCATION  !== 'left'? 0 : '50%',
    borderBottomLeftRadius: ({ WIDGET_LOCATION }) => WIDGET_LOCATION  === 'left'? 0 : '50%',
    borderRadius: ({USE_CUSTOM_LAUNCHER}) => USE_CUSTOM_LAUNCHER? 0 : 'auto',
    transition: '0.2s ease-in-out',
    '&.chat-open': {
      borderTopRightRadius: ({ WIDGET_LOCATION }) => WIDGET_LOCATION  !== 'left'? 0 : '50%',
      borderTopLeftRadius: ({ WIDGET_LOCATION }) => WIDGET_LOCATION  === 'left'? 0 : '50%',
      borderBottomRightRadius: '50%',
      borderBottomLeftRadius: '50%',
      borderRadius: ({USE_CUSTOM_LAUNCHER}) => USE_CUSTOM_LAUNCHER? 0 : 'auto',
      height: ({launchButtonSize}) => launchButtonSize * 0.8,
      width: ({USE_CUSTOM_LAUNCHER, USE_CUSTOM_LAUNCHER_ICON, CUSTOM_LAUNCHER_HEIGHT, CUSTOM_LAUNCHER_WIDTH, launchButtonSize}) => USE_CUSTOM_LAUNCHER&& !USE_CUSTOM_LAUNCHER_ICON ? launchButtonSize * 0.8 * CUSTOM_LAUNCHER_WIDTH / CUSTOM_LAUNCHER_HEIGHT : launchButtonSize * 0.8,
      background: ({USE_CUSTOM_LAUNCHER, USE_CUSTOM_LAUNCHER_ICON, GLOBAL_COLOR}) => USE_CUSTOM_LAUNCHER ? `url(${LauncherOpenImg}) no-repeat center center` : USE_CUSTOM_LAUNCHER_ICON ? `url(${LaunchIconImg}) no-repeat center center` : 'auto',
    },
    '&:hover': {
      background: ({USE_CUSTOM_LAUNCHER}) => USE_CUSTOM_LAUNCHER ? `url(${LauncherHoverImg}) no-repeat center center` : '',
      backgroundColor: ({USE_CUSTOM_LAUNCHER, USE_CUSTOM_LAUNCHER_ICON}) => USE_CUSTOM_LAUNCHER? 'transparent' : USE_CUSTOM_LAUNCHER_ICON ? 'none' : '#060606',
      // '-webkit-filter': ({GLOBAL_COLOR}) => `drop-shadow(0 0 5px ${GLOBAL_COLOR})`,
      // filter: ({GLOBAL_COLOR}) => `drop-shadow(0 0 5px ${GLOBAL_COLOR})`,
      transform: 'scale(1.1)',
    },
    '&.chat-open:hover': {
      background: ({USE_CUSTOM_LAUNCHER}) => USE_CUSTOM_LAUNCHER ? `url(${LauncherOpenHoverImg}) no-repeat center center` : '',
    },
    '&:active': {
      boxShadow: ({USE_CUSTOM_LAUNCHER}) => USE_CUSTOM_LAUNCHER ? 'none': 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      zIndex:({chatIsOpen}) => chatIsOpen ? -1 : 2000
    },
    [theme.breakpoints.up('sm')]: {
      display: ({ WIDE_MODE }) => WIDE_MODE ? 'none' : 'auto'
    }
  },
  messageIcon: {
    color: 'white',
    fontSize: 25,
    '&.fa-comments': {
      animation: '$fade-in 0.4s ease-in-out',
      '&.open': {
        animation: '$spin-in 0.3s ease-in-out',
      }
    },
    '&.fa-times': {
      animation: '$spin-out 0.3s ease-in-out',
      '&.open': {
        fontSize: 25 * 0.8,
        animation: '$spin-in 0.3s ease-in-out',
      }
    }
  },
  badge: {
    color: '#fff',
    right: ({CUSTOM_LAUNCHER_WIDTH}) => CUSTOM_LAUNCHER_WIDTH? - CUSTOM_LAUNCHER_WIDTH / 2 : 'auto',
    top: ({CUSTOM_LAUNCHER_HEIGHT}) => CUSTOM_LAUNCHER_HEIGHT? - CUSTOM_LAUNCHER_HEIGHT / 2: 'auto',
    fontFamily: ({FONT_FAMILY}) => FONT_FAMILY,
    backgroundColor: 'red',
    transition: '0.2s ease-in-out',
  },
  '@keyframes spin-out': {
    '0%': {
      transform: 'rotate(150deg)',
      opacity: 1
    },
    '100%': {
      transform: 'scale(rotate(270deg))',
      opacity: 0
    },
  },
  '@keyframes fade-in': {
    '0%': {
      opacity: 0
    },
    '100%': {
      opacity: 1
    },
  },
  '@keyframes spin-in': {
    '0%': {
      transform: 'rotate(-150deg)',
      opacity: 0
    },
    '100%': {
      transform: 'scale(rotate(10deg))',
      opacity: 1
    },
  },
  iconMinimisationButton: {
    zIndex: 2000,
    margin: theme.spacing(1),
    position: 'fixed',
    bottom: 200,
    height: 40,
    width: 40,
    backgroundColor:'transparent',
    transition: '0.2s ease-in-out',
    boxShadow: 'none',
  
    right: ({ WIDGET_LOCATION }) => WIDGET_LOCATION  !== 'left'? 10 : 'none',
    left: ({ WIDGET_LOCATION }) => WIDGET_LOCATION  === 'left'? 10 : 'none',
    borderBottomRightRadius: ({ WIDGET_LOCATION }) => WIDGET_LOCATION  !== 'left'? 0 : '50%',
    borderBottomLeftRadius: ({ WIDGET_LOCATION }) => WIDGET_LOCATION  === 'left'? 0 : '50%',
    
    '&.icon-minimised': {
      position: 'fixed',
      bottom: 10,
      background: ({MINIMIZE_LAUNCHER}) => MINIMIZE_LAUNCHER ? `url(${MinimisedIconImg}) no-repeat center center` : 'auto',
    },
    '&:hover': {
      backgroundColor: 'transparent',
      // '-webkit-filter': ({GLOBAL_COLOR}) => `drop-shadow(0 0 5px ${GLOBAL_COLOR})`,
      // filter: ({GLOBAL_COLOR}) => `drop-shadow(0 0 5px ${GLOBAL_COLOR})`,
      transform: 'scale(1.1)',
    },
    '&:active': {
      boxShadow:'none'
    }
  },
  closeButton: {
    boxShadow:'none',
    backgroundColor:'transparent',
    size:"small",
    '&:hover': {
      color: 'blue'
      
    }
  }
}));
